import React from "react"

import {
    Title,
    HeroSection, ValuePropositionSection, Capabilities, StickyNavBar,
    Industries, CustomerFeedback, Partners, Footer, OurWork, Paragraph, ContactUs
} from '../../components';
import { MainLayout } from '../../layouts'
import styles from "../../real-pages/LandingPage/styles.module.css";
import {CTAButton} from "./CTAButton/CTAButton";

export const Index = () => {
    return (
        <MainLayout>

            <HeroSection>
                <Title colour="white" weight="bold" size="large">
                    Dedicated to connect
                    <br/>
                    and transform your business
                </Title>
                <Paragraph className={styles.subtitle}>
                    Specialists, consultants, industry experts,
                    <br/>
                    delivering insights and actionable results
                </Paragraph>
                <CTAButton/>
            </HeroSection>
            <StickyNavBar/>
            <ValuePropositionSection/>
            <Capabilities/>
            <Industries/>
            <CustomerFeedback/>
            <OurWork/>
            <Partners/>
            <ContactUs/>
            <Footer/>
        </MainLayout>
    )
}
