import React from 'react';

import styles from './styles.module.css';

export const CTAButton = () => {
    return (
        <a className={ styles.CTAButton } href="#about-us">
            DISCOVER CALIBRATE
            <img className={styles.icon} src="/icons/down-arrow-white.svg"/>
        </a>
    )
};